.blinking {
    animation-name: blinker;
    animation-duration: 1s;
    animation-timing-function: linear;
    animation-iteration-count: 3;
  
    -webkit-animation-name: blinker;
    -webkit-animation-duration: 1s;
    -webkit-animation-timing-function: linear;
    -webkit-animation-iteration-count: 3;
  }
  
  @-moz-keyframes blinker {
    0% {
      opacity: 1.0;
    }
  
    50% {
      opacity: 0.0;
    }
  
    100% {
      opacity: 1.0;
    }
  }
  
  @-webkit-keyframes blinker {
    0% {
      opacity: 1.0;
    }
  
    50% {
      opacity: 0.0;
    }
  
    100% {
      opacity: 1.0;
    }
  }
  
  @keyframes blinker {
    0% {
      opacity: 1.0;
    }
  
    50% {
      opacity: 0.0;
    }
  
    100% {
      opacity: 1.0;
    }
  }
  