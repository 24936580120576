/* You can add global styles to this file, and also import other style files */
//@import "~@angular/material/prebuilt-themes/indigo-pink.css";
//@import './themes/material-theme';
@import './themes/variables';
// @import url('https://fonts.googleapis.com/icon?family=Material+Icons');
// @import url('https://fonts.googleapis.com/css?family=Ubuntu:300,400,500&amp;display=swap');

html,
body {
  min-height: 100%;
  // height: auto;
}

p {
  font-size: 30px;
  font-size: 3.5vw;
}

body {
  margin: 0;
  background-color: black;
//  font-family: 'Ubuntu'; //, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  min-height: 100%;
  height: auto;
  height: 100vh;


  // font-size: 3.5vw;
}

// Disable landscape
// @media screen and (min-width: 320px) and (max-width: 767px) and (orientation: landscape) {
//   html {
//     transform: rotate(-90deg);
//     transform-origin: left top;
//     width: 100vh;
//     overflow-x: hidden;
//     position: absolute;
//     top: 100%;
//     left: 0;
//   }
// }

// .title-bar {
//     background-color: #E59001;
// }

// .mat-button-wrapper {
//     background-color: #E59001;
//     width: 24px;
//     height: 24px;
// }

// $background: map-get($my-theme, background);

// // add hover for mat-icon butttons
// .mat-icon-button:hover {
//    background-color: mat-color($background, hover);
//  }

// .mat-button.mat-primary .mat-button-focus-overlay {
//     background-color: transparent;
// }

.top {
  vertical-align: top;
}

// .left {
//   float: left;
// }

.right {
  float: right;
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.card {
  max-width: 300px;
  //   max-height: 200px;
  margin: 2px;
  //   padding: 15px;
}



.dateDDWWW {
  // Example
  //   <mat-button-toggle [value]="o" (click)="onDateClick(o)" class="dateDDWWW">
  //   <p class="day">{{o.title}}</p>
  //   <p class="dow">{{o.dow}}</p>
  // </mat-button-toggle>

  // position: absolute;
  // bottom: 0;
  // right: 30px;
  // width: 48px;
  display: flex;
  // align-items: center;
  // justify-content: center;
  //  flex-direction: column;
  //  padding: 10px;
  //  background-color: rgba($primary, 0.8);
  //  background-color: blue;
  // text-shadow: 1px 1px rgba(black, 0.7);
  // transition: all 0.5s cubic-bezier(.25, .8, .25, 1);
  

  .day {
    line-height: 1;
    padding: 1px;
    margin: 0px;
    font-size: 20px;
    font-weight: 900;
  }

  .dow {
    line-height: 1;
    padding: 2px;
    margin: 0px;
    font-size: 14px;
    font-weight: 400;
  }
}


.ksd-cq {
  padding: 0px;
  font-size: 38px;
}

.ksd-mq {
  //  padding: 0px;
  font-size: 38px; // 38 is max
  color: orange;
}

.ksd-lq {
  //  font-family: 'DSEG7-Classic';
  //  font-weight: normal;
  //  font-style: italic;
  //    padding: 0px;
  font-size: 26px;
  color: blue;
}

.ksd-mq-missed {
  font-size: 38px; // 38 is max
  color: red;
}

.ksd-mq-calling {
  font-size: 38px; // 38 is max
  color: lawngreen;
  animation: blink 2s linear infinite;
}




.entity-title {
  // margin-top: 10px;
  // margin-bottom: 0;
  // padding: 0;
  padding-left: 5px;
  color: black;
  background-color: #B8D1F3;

}

.service-title {
  // margin: 0;
  padding-left: 5px;
  color: black;
  background-color: #D8E5F4;
}

.left {
  display: flex;
  // align-items: ;
  justify-content: left;
}

.full-height {
  height: 100vh;
  /* Use vh as a fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
  margin: 0 auto;
  max-width: 100%;
}


@import '@angular/material/theming';

// Define your own color palette
$custom-primary: mat-palette($mat-indigo);
$custom-accent: mat-palette($mat-orange, A200, A100, A400);
$custom-warn: mat-palette($mat-red);

// Create the theme using the custom palette
$custom-theme: mat-light-theme($custom-primary, $custom-accent, $custom-warn);

// Include the theme in your application
@include angular-material-theme($custom-theme);

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
